<template>
  <div class="row g-0" >
      <img src="../assets/main.png" class="image-left" />
      <div class="main-text">
        <h2>
          We are a family run business based in Aylesbury, Buckinghamshire, 
          supplying high quality fuels to surrounding areas. <br>
          <div style="height:15px;"/>
          We offer:
          <ul>
            <li>A reliable service </li>
            <li>Same day and next day deliveries where possible</li>
            <li>High quality fuel for your home or businesses</li>
            <li>24/7 customer service</li>
          </ul>
          <div style="height:15px;"/>
          We can deliver Gas oil, Kerosene, DERV and a variety of Biofuels to Bucks, Berks, Herts, Oxford and beyond.
          We are an approved UKIFDA supplier.
        </h2>
        <div style="margin-top:25px; width: 100%; text-align: center;">
          <button type="button" class="btn btn-light">Get a quote now!</button>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style scoped>

.image-left
{
  width:62%;
  height: auto;
  float:left;
}

.main-text
{
  padding: 20px;
  padding-top:75px;
  padding-bottom:75px;
  width: 38%;
  background-color: #335ba3;
  float: right;
}

@media not all and (min-resolution:.001dpcm) { 
    .image-left
    {
      width:62%;
      height:100%;
      float:left;
    }
}

@media screen and (max-width: 850px) {
  .image-left
  {
    width:100%;
    height: 100%;
  }

  .main-text
  {
    padding: 20px;
    padding-top:25px;
    width: 100%;
    background-color: #335ba3;
  }
}

@media screen and (max-width: 1200px) and (min-width: 850px) {
  .image-left
  {
    width:60%;
    height:100%;
    float:left;
  }

  .main-text
  {
    padding: 10px;
    padding-top:25px;
    background-color: #335ba3;
    font-size: 12pt;
    width: 40%;
    float: right;
  }
}

h1
{
  color: black;
  font-weight: 650;
  text-align: center;
  font-size: 5.5em;
}

h2
{
  color: white;
  font-weight: 350;
  font-size: 1.15em;
  line-height: 32px;
}

</style>